/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon as ExclamationIcon } from "@heroicons/react/24/outline/";
import { Textarea } from "@material-tailwind/react";
import axios from "axios";
import { BASE_URL } from "../../Axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

export default function RejectPropModal(props) {
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  const { openCloseFunction, openState } = props;

  const [reasonState, setReasonState] = useState("");

  const router = useHistory();

  const proposalId = router.location.pathname.split("/")[2];

  const handleRejectSubmit = async () => {
    console.log("Rejecting proposal");
    await axios({
      method: "put",
      baseURL: `${BASE_URL}/zoho/updateproposalandnotes`,
      data: {
        id: proposalId,
        Stage: "Proposal Declined",
        notes: reasonState,
      },
    });
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Proposal Rejected.",
      text: "Your have rejected the proposal. A Drummond representative will contact you as soon as possible!",
      showConfirmButton: false,
      timer: 2500,
    });
    window.location.replace("https://drummondadvisors.com/");
  };

  const handleTextareaChange = ({ target }) => {
    setReasonState(target.value);
  };

  return (
    <Transition.Root show={openState} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={openCloseFunction}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Reject Proposal
                  </Dialog.Title>
                  <div className="mt-2 flex flex-col gap-5 items-center pb-6">
                    <p className="text-sm text-gray-500">
                      It's fine if you want to reject your proposal, but please,
                      give us some feedback on the reason, and one of our
                      representatives will contact you!
                    </p>
                    <div className="w-full">
                      <Textarea
                        label="Message"
                        onChange={handleTextareaChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                  onClick={() => handleRejectSubmit()}
                >
                  Reject Proposal
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => openCloseFunction(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
