import {  useState } from "react";
import TriageContext from "./TriageContext";

const TriageProvider = ({ children }) => {
    const [triagePayload, setTriagePayload] = useState([]);
    const [signState, setSignState] = useState({})
    const [nextQuestionContext, setNextQuestionContext] = useState('');
    const [answeredQuestionsId, setAnsweredQuestionsId] = useState([]);
    const [providedAnswers, setProvidedAnswers] = useState([]);
    const [currAnswer, setCurrAnswer] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [proposalInformation, setProposalInformation] = useState({})
    const [incomingInfo, setIncomingInfo] = useState({});
    const [proposalId, setProposalId] = useState("");
    const [proposalGeneratedStatus, setProposalGeneratedStatus] = useState("");
    const [userAuthenticationStatus, setUserAuthenticationStatus] = useState(false);
    const [servicesID, setServicesID] = useState([])
    const [proposalServices, setProposalServices] = useState([])
    const [pageLanguage, setPageLanguage] = useState("EN");

    const context = {
      triagePayload,
      setTriagePayload,
      signState,
      setSignState,
      nextQuestionContext,
      setNextQuestionContext,
      answeredQuestionsId,
      setAnsweredQuestionsId,
      providedAnswers,
      setProvidedAnswers,
      currAnswer,
      setCurrAnswer,
      userEmail,
      proposalInformation,
      setProposalInformation,
      setUserEmail,
      incomingInfo,
      setIncomingInfo,
      proposalId,
      setProposalId,
      proposalGeneratedStatus,
      setProposalGeneratedStatus,
      userAuthenticationStatus,
      setUserAuthenticationStatus,
      servicesID,
      setServicesID,
      proposalServices,
      setProposalServices,
      pageLanguage,
      setPageLanguage,
    };

    return (
      <TriageContext.Provider value={context}>{children}</TriageContext.Provider>
    );
  };
  export { TriageContext, TriageProvider as Provider };
  
