import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import './App.css';
import Diagnostics from "./Pages/Diagnostics";
import GenerateDiagnostic from "./Pages/GenerateDiagnostic";
// import Home from "./Pages/OldHome";
import Information from "./Pages/Information";
import Proposal from "./Pages/Proposal";
import Proposal_investment from "./Pages/Proposal-investment";
import Proposal_next_steps from "./Pages/Proposal-next-steps";
// import ProposalpdfDownload from './Pages/ProposalpdfDownload';
import Proposal_sign from "./Pages/Proposal-sign";
import Proposal_welcome from "./Pages/Proposal-welcome";
import "./QuestionLoading.css";
import "./homeLoader.css";
import "./paymentButtonLoader.css";
import BusinessFormationHome from "./Pages/BusinessFormation";
import ValidateTriageUser from "./Pages/ValidateTriageUser";
import ForbiddenPage from "./Pages/ForbiddenPage";
import NotFoundPage from "./Pages/NotFound";
import Checkout from "./Pages/Checkout";
import Home from "./Pages/Home";
import RejectPropModal from "./Components/organisms/RejectPropModal";
import ProposalWelcomeTest from "./Pages/ProposalWelcomeTest";
import ProposalInvestmentTest from "./Pages/ProposalInvestmentTest";
import ProposalSignTest from "./Pages/ProposalSignTest";
import ProposalNextSteps from "./Pages/ProposalNextStepsTest";

function App() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/forbidden" component={ForbiddenPage} />
      <Route
        exact
        path="/businessformation"
        component={BusinessFormationHome}
      />
      <Route exact path="/proposal/:id" component={Proposal} />
      <Route
        exact
        path="/validateuser/:triageId"
        component={ValidateTriageUser}
      />
      <Route
        exact
        path="/proposal/:id/welcome"
        component={ProposalWelcomeTest}
        // component={Proposal_welcome}
      />
      <Route exact path="/checkout/:id" component={Checkout} />
      <Route
        exact
        path="/proposal/:id/investment"
        component={ProposalInvestmentTest}
        // component={Proposal_investment}
      />
      {/* <Route exact path="/proposal/:id/sign" component={Proposal_sign} /> */}
      <Route exact path="/proposal/:id/sign" component={ProposalSignTest} />
      <Route
        exact
        path="/proposal/:id/next-steps"
        // component={Proposal_next_steps}
        component={ProposalNextSteps}
      />
      <Route exact path="/information" component={Information} />
      <Route path="/generatediagnostic/:data" component={GenerateDiagnostic} />
      <Route exact path="/diagnostics" component={Diagnostics} />
      {/* <Route exact path="/test/:id" component={ProposalWelcomeTest} /> */}
      <Route path="/test" component={RejectPropModal} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}

export default App;
