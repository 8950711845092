import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import TriageContext from '../context/TriageContext'
import "../GenerateDiagnosticLoading.css";
import drummondLogo from "../assets/logo-drummond.jpg";
import { BASE_URL } from '../Axios'


export default function Proposal() {
  const { id } = useParams();
  const url = `/proposal/${id}/welcome`;
  const [redirect, setRedirect] = useState(false);

  const { setProposalInformation, setSignState, setUserEmail, setProposalServices } =
    useContext(TriageContext);

  const getProposalInformation = async (id) => {
    try {
      const proposal = await axios({
        method: "get",
        url: `${BASE_URL}/zoho/proposal/${id}`,
      }).then((proposal) => {
        console.log(proposal);
        return proposal;
      });
      // console.log(proposal.data);
      setProposalInformation(proposal.data);
      setProposalServices(proposal.data.Proposal_Services);
      setUserEmail(proposal.data.Email);
      setRedirect(true);
      const email = proposal.data.Email;
      // console.log('email: ' + email);
      const result = await axios({
        method: "get",
        url: `${BASE_URL}/firebase/${proposal.data.Triage_ID}`,
      }).then((result) => {
        return result.data;
      });
      setSignState({
        email,
        name: result.signature.name,
        signedStatus: result.signature.signedStatus,
        time: result.signature.time,
      });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    Promise.resolve(getProposalInformation(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {redirect ? (
        <Redirect to={url} />
      ) : (
        <div className="border-4 border-red-800 h-screen flex flex-col justify-center items-center">
          <img src={drummondLogo} width="200" height="200" alt="logo" />
          <p className="flex text-xl">Searching for your proposal...</p>
          <div className="flex justify-center h-32 w-32">
            <div className="generateLoading">Loading...</div>
          </div>
        </div>
      )}
    </div>
  );
}
