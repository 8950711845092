import { RadioGroup } from '@headlessui/react';
import { useContext, useEffect, useState } from 'react';
import TriageContext from '../../context/TriageContext';

function RadioInputComponent(props) {
  const { information } = props;
  const [selected, setSelected] = useState(information[0]);
  const { setNextQuestionContext, setCurrAnswer, pageLanguage } =
    useContext(TriageContext);


  useEffect(() => {
    setNextQuestionContext(selected);
    setCurrAnswer(selected);;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const optionTitleSwitcher = (element) => {
    switch (pageLanguage) {
      case "EN":
        return element.optionTitle.english;
      case "ES":
        return element.optionTitle.spanish;
      case "PT":
        return element.optionTitle.portuguese;
      default:
        break;
    }
  };

  return (
    <div className="w-full px-4">
      <div className="mx-auto max-w-md">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-6 md:space-y-12">
            {information
              .filter((info) => info.enabled)
              .map((option, index) => (
                <RadioGroup.Option
                  key={index}
                  value={option}
                  className={({ active, checked }) =>
                    `${
                      active
                        ? "ring-2 ring-white ring-opacity-60 border-2 duration-200 ring-offset-2 ring-offset-drummond-primary"
                        : ""
                    }
                  ${
                    checked
                      ? "bg-drummond-primary text-white md:scale-110"
                      : "bg-gray-300"
                  }
                    relative flex cursor-pointer rounded-[8px] font-dinbold px-5 py-4 md:px-6 md:py-4 shadow-lg focus:outline-none`
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium ${
                                checked
                                  ? "text-white text-[18px]"
                                  : "text-gray-800"
                              }`}
                            >
                              {optionTitleSwitcher(option).replace(
                                new RegExp('"', "g"),
                                ""
                              )}
                              {/* {option.option} */}
                            </RadioGroup.Label>
                            <RadioGroup.Description
                              as="span"
                              className={`inline ${
                                checked
                                  ? "text-sky-300 text-xs italic"
                                  : "text-slate-400 text-xs italic"
                              }`}
                            ></RadioGroup.Description>
                          </div>
                        </div>
                        {checked && (
                          <div className="shrink-0 text-white">
                            <CheckIcon className="h-6 w-6" />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
  
  export default RadioInputComponent;
