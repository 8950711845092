import React, { useEffect, useState } from "react";
// import proposal from '../mocks/proposal.json'
import NavBar from "../Components/molecules/NavBar";
import StepsBar from "../Components/organisms/StepsBar";
import Footer from "../Components/molecules/Footer";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useContext } from "react";
import TriageContext from "../context/TriageContext";
import { useRef } from "react";

export default function ProposalWelcomeTest() {
  const { id } = useParams();
  const [proposalData, setProposalData] = useState({
    name: "",
  });

  const history = useHistory();

  const { proposalInformation } = useContext(TriageContext);
  const welcomeRef = useRef();
  const fetchDataFromCRM = async () => {
    setProposalData({
      name:
        proposalInformation.Lead?.name ||
        proposalInformation.Contact?.name ||
        "",
    });
  };
  useEffect(() => {
    if (!proposalInformation.Proposal_Services) {
      history.push(`/proposal/${id}`);
    }
  });

  useEffect(() => {
    fetchDataFromCRM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposalInformation]);

  return (
    <main className="flex flex-col h-screen bg-white" ref={welcomeRef}>
      <NavBar />
      <div className="hidden md:block lg:w-[1094px] sm:w-11/12 self-center items-center">
        <StepsBar />
      </div>
      <div className="flex flex-col flex-1 justify-center items-center">
        <div className="flex flex-col items-center space-y-12 w-full lg:w-1/2 h-5/6 md:py-8">
          <h1 className="self-center font-dinbold text-4xl md:text-[42px] text-drummond-neutral-400">
            Welcome
          </h1>
          <div className="w-11/12 md:w-[609px] md:h-[120px] self-center flex flex-col gap-4">
            <p className="text-base font-asap text-drummond-neutral-400 leading-normal">
              Welcome aboard,
              <span className="font-dinbold text-black text-md">
                {proposalData.name}
              </span>
              ! We are happy that you chose Drummond Advisors. We are a company
              that was born with the mission of meeting the needs of companies
              operating in Brazil and in the United States, building bridges
              that connect entrepreneurs to new markets, new business partners,
              new collaborators and new investors abroad. We have over 12 years
              of experience and 180 professionals with the purpose of serving
              you.
            </p>
          </div>
          <div className="pt-[8px] flex w-11/12 md:w-full justify-center self-center">
            <button
              className=" h-[56px] w-[609px] font-dinbold items-center text-lg text-white rounded-md duration-100 bg-drummond-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400"
              onClick={() => history.push(`/proposal/${id}/investment`)}
            >
              See my Proposal
            </button>
          </div>
        </div>
      </div>
    </main>
  );
}
