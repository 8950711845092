import React from "react";
import { useContext } from "react";
import { languageSwitchObject } from "../../assets/switchObject";
import TriageContext from "../../context/TriageContext";

function ServiceCard(props) {
  const { pageLanguage } = useContext(TriageContext);

  const serviceNameSwitcher = (element) => {
    switch (pageLanguage) {
      case "EN":
        return element.serviceName.english;
      case "ES":
        return element.serviceName.spanish;
      case "PT":
        return element.serviceName.portuguese;
      default:
        break;
    }
  };

  const serviceReasoningSwitcher = (element) => {
    switch (pageLanguage) {
      case "EN":
        return element.serviceReasoning.english;
      case "ES":
        return element.serviceReasoning.spanish;
      case "PT":
        return element.serviceReasoning.portuguese;
      default:
        break;
    }
  };

  return (
    <div className="overflow-hidden w-full rounded-md bg-white shadow-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-dinbold font-medium leading-6 text-gray-900">
          {serviceNameSwitcher(props.service)}
        </h3>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium font-asap text-gray-500">
              {languageSwitchObject.serviceCardName[pageLanguage]}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 font-asap sm:col-span-2 sm:mt-0">
              {serviceNameSwitcher(props.service)}
            </dd>
          </div>
          {props.service.serviceReasoning ? (
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500 font-asap">
                {languageSwitchObject.serviceCardReasoning[pageLanguage]}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 font-asap">
                {serviceReasoningSwitcher(props.service)}
              </dd>
            </div>
          ) : null}
        </dl>
      </div>
    </div>
  );
}

export default ServiceCard;
