import { useContext, useEffect } from "react";
import TriageContext from "../../context/TriageContext";

function TextInputComponent(props) {
    // const { information } = props;
    const { setNextQuestionContext, setCurrAnswer } = useContext(TriageContext);

    const handleChange = ({ target }) => {
      setCurrAnswer({ ...props.information, option: target.value });
      // setNextQuestionContext(props.information.nextQuestion)
    };

    useEffect(() => {
      setNextQuestionContext(props.information);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <input type='text' onChange={handleChange} className="mt-1 block w-5/6 border border-gray-300 rounded-md shadow-sm self-center py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder={props.information.label}
      />
    );
  }
  
  export default TextInputComponent;