      // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-9C2PiriahCgRtPRSeSlnMcVPnW5Hu_o",
  authDomain: "drummondtechprojects.firebaseapp.com",
  projectId: "drummondtechprojects",
  storageBucket: "drummondtechprojects.appspot.com",
  messagingSenderId: "709140088614",
  appId: "1:709140088614:web:15bfdb948883aeb81ee3e7",
  measurementId: "G-DG12EN7F7G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);