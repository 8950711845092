function InvestmentServicesHeader(props) {
  return (
    <section className="h-[75px] flex items-center justify-between w-full px-[8px] md:px-[32px] py-[22px] sticky top-0 bg-white z-10 shadow-[0px_1px_4px_rgba(0,0,0,0.16)]">
      <div className="flex items-center gap-1 md:gap-6">
        <p className="text-[20px] md:text-[24px] md:leading-[31px] font-bold font-dinbold">
          Services {`(${props.proposalDataServices.length})`}
        </p>
        <p className="text-drummond-primary font-asap text-[12px] md:text-[14px]">
          {props.proposalServices.length === 0
            ? "No services selected yet"
            : `${props.proposalServices.length} service(s) selected`}
        </p>
      </div>
      <div className="flex flex-col">
        <p className="text-primary font-dinbold text-[24px] text-right">{`Total: $${props.proposalServices.reduce(
          (acc, curr) => acc + curr.Unit_Price,
          0
        )}`}</p>
        <p className="text-[12px] font-asap text-drummond-neutral-400">
          Starting on{" "}
          {Date(props.proposalDataServices.createTime)
            .split(" ")
            .filter((_i, index) => index <= 3 && index > 0)
            .join(" ")}
        </p>
      </div>
    </section>
  );
}

export default InvestmentServicesHeader;
