import CheckboxInputComponent from '../atoms/CheckboxInputComponent';
import RadioInputComponent from '../atoms/RadioInputComponent';
import SelectComponent from '../atoms/SelectComponent';
import TextareaInputComponent from '../atoms/TextareaInputComponent';
import TextInputComponent from '../atoms/TextInputComponent';

function DynamicForm(props) {
  const { element } = props;

  const typeSwitcher = () => { 
    switch (element.questionInputType) {
    // switch (element.type) {
      case "select":
        return <SelectComponent information={element.options} />;
      case "text":
        return <TextInputComponent information={element} />;
      case "checkbox":
        return <CheckboxInputComponent information={element.options} />;
      case "textarea":
        return <TextareaInputComponent information={element.options} />;
      case "radio":
        return <RadioInputComponent information={element.questionOptions} />;
      default:
        return null;
    }
  };
  return (
    <>
    {typeSwitcher()}
    </>
  );
}

export default DynamicForm;
