export const languageSwitchObject = {
  firstName: {
    EN: "First Name",
    ES: "Primer nombre",
    PT: "Primeiro Nome",
  },
  lastName: {
    EN: "Last Name",
    ES: "Apellido",
    PT: "Sobrenome",
  },
  emailAddress: {
    EN: "E-mail Address",
    ES: "Correo Electrónico",
    PT: "Endereço de E-mail",
  },
  phone: {
    EN: "Phone (optional)",
    ES: "Teléfono (opcional)",
    PT: "Telefone (opcional)",
  },
  submitButton: {
    EN: "Submit",
    ES: "Enviar",
    PT: "Enviar",
  },
  descriptionButton: {
    EN: "Details",
    ES: "Detalles",
    PT: "Detalhes",
  },
  informationPageTitle: {
    EN: "Personal Information",
    ES: "Informaciones Personales",
    PT: "Informações Pessoais",
  },
  informationPageDescription: {
    EN: "Please share your information with us so we can send you a complete diagnostic via e-mail.",
    ES: "Comparta su información con nosotros para que podamos enviarle un diagnóstico completo por e-mail",
    PT: "Por favor, compartilhe conosco suas informações que lhe enviaremos um diagnóstico completo por e-mail",
  },
  serviceCardReasoning: {
    EN: "Why are we offering this service",
    ES: "¿Por qué ofrecemos este servicio?",
    PT: "Por que oferecemos este serviço?",
  },
  serviceCardName: {
    EN: "Service Name",
    ES: "¿Por qué ofrecemos este servicio?",
    PT: "Por que oferecemos este serviço?",
  },
};
