import React, { useContext } from "react";
import TriageContext from "../../context/TriageContext";

function DesktopLanguageBar() {
  const { pageLanguage, setPageLanguage } = useContext(TriageContext);
  return (
    <div className="hidden md:flex md:justify-center md:w-1/3 md:h-full">
      <div className="h-1/2 w-1/3 self-center flex justify-evenly items-center">
        <p
          className={`${
            pageLanguage === "EN"
              ? "text-drummond-primary underline underline-offset-8 decoration-2	"
              : ""
          } text-[18px] font-dinbold cursor-pointer duration-150`}
          onClick={() => setPageLanguage("EN")}
        >
          US
        </p>
        <p
          className={`${
            pageLanguage === "PT"
              ? "text-drummond-primary underline underline-offset-8 decoration-2	"
              : ""
          } text-[18px] font-dinbold cursor-pointer`}
          onClick={() => setPageLanguage("PT")}
        >
          BR
        </p>
        <p
          className={`${
            pageLanguage === "ES"
              ? "text-drummond-primary underline underline-offset-8 decoration-2	"
              : ""
          } text-[18px] font-dinbold cursor-pointer duration-150`}
          onClick={() => setPageLanguage("ES")}
        >
          ES
        </p>
      </div>
    </div>
  );
}

export default DesktopLanguageBar;
