import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

export default function SeeMoreModal(props) {
  const { seeMoreModalOpen, setSeeMoreModalOpen, data } = props;
  const cancelButtonRef = useRef(null);

  console.log(props);

  return (
    <Transition.Root show={seeMoreModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setSeeMoreModalOpen}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white pb-[32px] rounded-[5px] overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[709px] sm:w-full">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title className="text-[24px] h-[75px] px-[32px] flex justify-between items-center text-start leading-6 font-medium text-gray text-drummond-neutral-400-900">
                    <h1 className="text-drummond-neutral-400 font-dinbold text-[24px] text-drummond-neutral-400px]">
                      {data.title}
                    </h1>
                    <button
                      type="button"
                      onClick={() => setSeeMoreModalOpen(false)}
                      ref={cancelButtonRef}
                    >
                      <XMarkIcon className="text-black h-7 w-7" />
                    </button>
                  </Dialog.Title>
                  <div className="px-[32px]">
                    <p className="text-[16px] font-asap text-drummond-neutral-400">
                      {data.description}
                    </p>
                  </div>
                </div>
              </div>{" "}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
