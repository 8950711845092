import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import NavBar from '../Components/molecules/NavBar';
import TriageContext from '../context/TriageContext';

function ValidateTriageUser() {
    const [accessCodeState, setAccessCodeState] = useState({accessCode: ''})
    const history = useHistory();
    const params = useParams();
    const {setUserAuthenticationStatus} = useContext(TriageContext)

    const handleChange = ({target}) => {
        setAccessCodeState({accessCode: target.value})
    }

    const userValidation = async (triageId, accessCode) => {
        try {
            const result = await axios({
                method: 'POST',
                url: 'https://us-central1-drummondtechprojects.cloudfunctions.net/api2/firebase/diagnosticvalidation',
                headers: {
                    Authorization: accessCode,
                },
                data: {
                    id: triageId,
                }
            }).then((response) => response.data.message === 'Authorized')
            // console.log(result)
            if (result) {
                Swal.fire({
                    title: 'User Validated!',
                    text: 'You have been validated. Redirecting you...',
                    icon: 'success',
                    confirmButtonColor: "#991C1C"
                })
                setUserAuthenticationStatus(true)
                setTimeout(() => {
                    history.push('/diagnostics')
                }, 3000);
            }
        } catch(err) {
            console.log(err)
            Swal.fire({
                title: 'Wrong access code',
                text: 'Incorrect access code, please refer to your e-mail or contact us.',
                icon: 'error',
                confirmButtonColor: "#991C1C"
            })
        }
    }

    return ( 
        <div className='flex flex-col h-screen'>
            <NavBar />
            <div className='flex bg-slate-100 flex-1 justify-center items-center'>
                <div className='flex flex-col items-center h-1/2 w-11/12 md:h-1/2 md:w-1/2'>
                    <h1 className='font-robotocond text-3xl'>User Validation</h1>
                    <h1 className='p-2 font-robotocond text-md text-center text-slate-400'>You have received an access code with your your diagnostic link on your e-mail.</h1>
                <div className='w-full flex flex-col items-center flex-1 justify-center'>
                <label
                  htmlFor="email-address"
                  className="font-robotocond block text-md font-medium w-5/6 self-center text-gray-700"
                >
                  Diagnostic Access Code:
                </label>
                <input
                  onChange={handleChange}
                  type="password"
                  name="accessCode"
                  id="accessCode"
                  required
                  className="mt-1 block w-5/6 border border-gray-300 rounded-md shadow-sm self-center py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                </div>
                <button
                type="button"
                className="px-2 py-1 self-center bg-red-800 rounded-md text-white duration-100 hover:bg-red-600"
                onClick={async () => userValidation(params.triageId, accessCodeState.accessCode)}
              >
                Submit
              </button>
                </div>
            </div>
        </div>
     );
}

export default ValidateTriageUser;