import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import TriageContext from "../../context/TriageContext";

function MobileFooter(props) {
  const history = useHistory();
  const { id } = useParams();
  const { signState, userEmail } = useContext(TriageContext);

  const redirectToPage = (route) => {
    if (route === "checkout") {
      history.push(`/${route}/${id}`);
    } else {
      history.push(`/proposal/${id}/${route}`);
    }
  };

  const [validateSignatures, setValidateSignaures] = useState(
    !history.location.pathname.includes("sign")
  );

  const validateNextBtn = async () => {
    if (
      history.location.pathname.includes("welcome") ||
      history.location.pathname.includes("next")
    ) {
      setValidateSignaures(false);
    }
    if (history.location.pathname.includes("sign")) {
      // signState.forEach(i => {
      if (signState.signedStatus) {
        setValidateSignaures(true);
      } else {
        setValidateSignaures(false);
      }
      // })
    }
  };

  useEffect(() => {
    validateNextBtn();
  });

  const changeRoute = (type) => {
    const route = history.location.pathname;
    if (type === "next") {
      switch (route) {
        case `/proposal/${id}/welcome`:
          redirectToPage("investment");
          break;
        case `/proposal/${id}/investment`:
          redirectToPage("sign");
          break;
        case `/proposal/${id}/sign`:
          redirectToPage("next-steps");
          break;
        default:
          break;
      }
    }
    if (type === "back") {
      switch (route) {
        case `/proposal/${id}/investment`:
          redirectToPage("welcome");
          break;
        case `/proposal/${id}/sign`:
          redirectToPage("investment");
          break;
        case `/proposal/${id}/next-steps`:
          redirectToPage("sign");
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="w-full md:hidden h-16 flex items-center justify-between px-[32px]">
      <button
        className="w-[124px] h-[47px] rounded-[4px] duration-100 font-dinbold text-[16px] text-primary border border-primary font-bold bg-white hover:bg-primary hover:text-white active:text-white active:bg-drummond-secondary-400"
        onClick={() => changeRoute("back")}
      >
        Previous Step
      </button>
      {!validateSignatures || history.location.pathname.includes("welcome") ? (
        <div className="invisible w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400">
          Next Step
        </div>
      ) : (
        <button
          className="w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400"
          onClick={() => changeRoute("next")}
        >
          Next Step
        </button>
      )}
    </div>
  );
}

export default MobileFooter;
