import { useState } from "react";
import ModalProposalComponent from "../atoms/ModalProposalComponent";
import ServiceCheckbox from "../atoms/ServiceCheckbox";
import RejectPropModal from "./RejectPropModalTest";
import SeeMoreModal from "./SeeMoreModal";

function ServiceItemElement(props) {
  const { service, index } = props;
  const [seeMoreModalOpen, setSeeMoreModalOpen] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const verifyClassNames = () => {
    if (enabled) {
      return "border-drummond-primary";
    } else {
      return "hover:shadow-[0px_41px_16px_rgba(202,196,196,0.01),0px_23px_14px_rgba(202,196,196,0.05),0px_10px_10px_rgba(202,196,196,0.09),0px_3px_6px_rgba(202,196,196,0.1),0px_0px_0px_rgba(202,196,196,0.1)] duration-150";
    }
  };

  return (
    <section
      className={`${verifyClassNames()} p-[12px] md:p-[16px] w-11/12 sm:w-[700px] rounded-lg flex flex-col border lg:w-[1030px] first-of-type:mt-[14px] last-of-type:mb-[20.5px]`}
    >
      <div
        key={index}
        className="border bg-drummond-neutral-100 flex items-center px-2 rounded-t-lg md:h-[52px]"
      >
        <div className="flex gap-[16px] items-center">
          <ServiceCheckbox
            enabled={enabled}
            setEnabled={setEnabled}
            id={service.id}
            service={service}
          />
          <p className="text-[18px] font-dinbold text-drummond-neutral-400">
            {service.Service.name}
          </p>
        </div>
      </div>
      <table className="">
        <tr className="bg-drummond-neutral-50 rounded-b-lg h-[40px]">
          <th className="font-asap text-[14px] text-drummond-neutral-400 px-[12px] py-[16px]">
            Price
          </th>
          <th className="font-asap text-[14px] text-drummond-neutral-400 px-[12px] py-[16px]">
            Receiver
          </th>
          <th className="font-asap text-[14px] text-drummond-neutral-400 px-[12px] py-[16px]">
            Recurrence
          </th>
          <th className="font-asap text-[14px] text-drummond-neutral-400 px-[12px] py-[16px]">
            Payment Terms
          </th>
        </tr>
        <tr className="bg-white text-center">
          <td className="text-[16px] font-dinbold text-drummond-secondary-50 font-bold p-2">
            ${service.Unit_Price}
          </td>
          <td className="text-[16px] font-dinbold text-drummond-secondary-50 font-bold p-2">
            {service.Account
              ? service.Account.name
              : service.Lead
              ? service.Lead.name
              : service.Receiver}
          </td>
          <td className="text-[16px] font-dinbold text-drummond-secondary-50 font-bold p-2">
            {service.Recurrence}
          </td>
          <td className="text-[16px] font-dinbold text-drummond-secondary-50 font-bold p-2">
            {service.Payment_Terms}
          </td>
        </tr>
      </table>
      <div className="mt-4 px-3">
        <p className="font-asap text-[16px] text-drummond-neutral-300 font-light">
          {service.Description}
        </p>
      </div>
      <div className="h-8 mt-6 flex justify-end items-center">
        <button
          onClick={() => setSeeMoreModalOpen(!seeMoreModalOpen)}
          className="flex items-center justify-center font-dinbold duration-100 border-primary text-primary bg-white font-dmsans h-[32px] w-[115px] px-[20px] border rounded-[4px] hover:text-white hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400 active:text-white"
        >
          See more
        </button>
        {seeMoreModalOpen ? (
          <SeeMoreModal
            seeMoreModalOpen={seeMoreModalOpen}
            setSeeMoreModalOpen={setSeeMoreModalOpen}
            data={{ title: service.Service.name, description: service.Note }}
          />
        ) : null}
      </div>
    </section>
  );
}

export default ServiceItemElement;
