// import tosObject from "../../assets/ToSObject";
function ToSBoard(props) {
  return (
    <div className="p-[16px] border flex flex-col border-drummond-neutral-200 bg-drummond-neutral-100 rounded-[10px] bg-opacity-[0.2] w-full lg:w-[1030px] h-72 self-center overflow-y-scrol overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-thumb-rounded-lg scrollbar-h-4 gap-1">
      <h1 className="text-[20px] font-dinbold text-drummond-neutral-400 self-center mb-4">
        DRUMMOND'S GENERAL TERMS AND CONDITIONS OF SERVICES
      </h1>
      <p className="font-dinlight text-[16px] text-drummond-neutral-400 font-bold">
        THIS AGREEMENT GOVERNS YOUR RELATIONSHIP WITH US AND THE USE OF OUR
        SERVICES. BY EXECUTING A SERVICE ORDER THAT REFERENCES THIS AGREEMENT,
        YOU AGREE TO THE TERMS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS
        AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT
        THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY, ITS SUBSIDIARIES AND
        AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS "YOU"
        OR "YOUR" SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES. IF YOU DO NOT
        HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND
        CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT NOR EXECUTE THE SERVICE
        ORDER AND MAY NOT USE THE SERVICES. This Agreement was last updated on
        April 5, 2021. It is effective between You and Us as of the date of your
        acceptance of this Agreement by executing the appropriate Service Order.
      </p>
      <br />
      <div className="flex flex-col font-asap">
        <span className="font-dinbold text-[18px] text-drummond-secondary-50">
          1.Definitions
        </span>
        "Affiliate" means any entity that directly or indirectly controls, is
        controlled by, or is under common control with the subject entity.
        “Agreement” means this Terms and General Conditions of Services.
        “Service Order” means an ordering document or otherwise an order
        specifying the Services to be provided hereunder that is entered into
        between You and Drummond, including any addenda and supplements thereto.
        “Party” or “Parties” means You and/or We,as the case may be. “Purchased
        Services” or “Services” means the services that You or Your Affiliate
        request under a Service Order. “Drummond”, “We”, “Us” or “Our” means one
        or more of the following legal entities, that will be providing services
        to You as set forth on the applicable Service Order: Drummond CPA LLC,
        Drummond Legal Advisors PLLC, Drummond Consulting LLC, Drummond Advisors
        LLC, Drummond Ventures LLC, Drummond Sociedade de Advogados, Drummond
        Consultoria CPA Ltda. and Drummond Outsourcing Ltda. “You” or “Your”
        means you or the company or other legal entity for which you are
        accepting this Agreement, its Subsidiaries and Affiliates of that
        company or entity which have signed a Service Order, and are thereby
        entitled to receive Our Services.
      </div>
      <br />
      <div className="flex flex-col font-asap">
        <span className="font-dinbold text-[18px] text-drummond-secondary-50">
          2. Scope of Services
        </span>
        <div>
          <div>
            <span className="font-dinbold">2.1. </span>
            To the extent requested on the applicable Service Order, Drummond
            will provide outsourced consulting services to You. Drummond will be
            responsible for overall engagement oversight and consultation and
            will report directly to Your senior management or to outside service
            providers as may be directed by You.
          </div>
          <br />
          <div className="font-asap">
            <span className="font-dinbold">2.2. </span> Duties to be assumed by
            Drummond will include those described on the applicable Service
            Order and are subject to the limitations and qualifications set
            forth thereon.
          </div>
          <br />
          <div className="font-asap">
            <span className="font-dinbold">2.3. </span> Drummond may utilize
            other specialists, including employees and independent contractors,
            in the execution of the above role (“Temporary Specialists”). As
            appropriate, these resources may be asked to participate in meetings
            and discussions with You, either on site or remotely.
          </div>
          <br />
          <div className="font-asap">
            <span className="font-dinbold">2.4. </span> No Assurance on
            Financial Data. Because of the nature of the services to be
            performed under this Agreement and the time and scope limitations,
            We lack independence necessary to perform attest services. Further,
            the depth of Our analyses and verification of the data is
            significantly limited. Therefore, We are not being requested to
            perform an audit or to apply generally accepted auditing standards
            or procedures for such purposes.
          </div>
        </div>
      </div>
      <br />
      <div className="flex flex-col">
        <span className="font-dinbold text-[18px] text-drummond-secondary-50">
          3. Your Responsibilities
        </span>
        <p className="font-asap">
          <span className="font-dinbold">3.1. </span>In addition to other
          provisions of this Agreement, You are responsible for providing all
          data, documents, and interviews requested by Drummond in a reasonably
          timely and complete manner. Also, you are responsible for designating
          an individual who possesses suitable skills, knowledge, and/or
          experience, to serve as the liaison between You and Drummond for all
          reasonable purposes. Drummond will not be responsible for the effects
          on Drummond’s work created by your delays and/or incomplete
          information that is reasonably expected to be available.
        </p>
      </div>
      <br />
      <div className="flex flex-col">
        <span className="font-dinbold text-[18px] text-drummond-secondary-50">
          4. Fees
          <br />
        </span>
        <div className="text-asap">
          <span className="font-dinbold">4.1. </span>
          Our fees and payment terms are described on the applicable Service
          Order, which is hereby incorporated by reference for all legal
          purposes. We may review and adjust our hourly rates and fixed fees
          from time to time, generally as of January 1st of each year. In such
          case, any rate adjustments will be reflected in your invoice of the
          following month.
        </div>
        <br />
        <div className="font-asap">
          <span className="font-dinbold">4.2. </span>
          In addition to the above fees, You shall reimburse Drummond for any
          and all out-of-pocket expenses, including, but not limited to,
          government fees, communication and travel costs, mail, and other
          expenses indicated in the appropriate Service Order.
        </div>
        <br />
        <div>
          <span className="font-dinbold">4.3. </span> In the event of any
          collection, dispute, litigation, or court proceeding arising out of,
          or in connection with this Agreement, We will be entitled to recover
          attorneys’ fees and costs or expenses incurred in such dispute.
        </div>
        <br />
        <div className="font-asap">
          <span className="font-dinbold">4.4. </span> Unless otherwise stated in
          the Service Order, invoiced charges are due net 10 days from the
          invoice date. Payments in installments are due on the specific date
          set forth on the corresponding invoices, regardless of the timeline of
          the project. You are responsible for providing complete and accurate
          billing and contact information to Us, and notifying Us of any changes
          to such information.
        </div>
        <br />
        <div>
          <span className="font-dinbold">4.4.1. </span> Late payments shall be
          subject to a penalty equals to 10% or US$50.00, whichever is greater;
          plus interest rate of 0.5% per month calculated on a pro rata basis.
        </div>
        <br />
        <div>
          <span className="font-dinbold">4.4.2. </span> The preferable payment
          mean is through Automated Clearing House - ACH. However, for some of
          Our Services We may accept alternative payment means, as provided for
          in the applicable Service Order.
        </div>
        <br />
        <div>
          <span className="font-dinbold">4.5. </span>Our fees do not include any
          taxes, levies, duties or similar governmental assessments of any
          nature, including, for example, value-added, sales, use or withholding
          taxes, assessable by any jurisdiction whatsoever (collectively,
          “Taxes”). You are responsible for paying all Taxes associated with
          Your purchases hereunder. If We have the legal obligation to pay or
          collect Taxes for which You are responsible, We will invoice You and
          You will pay that amount unless You provide Us with a valid tax
          exemption certificate authorized by the appropriate taxing authority.
          For clarity, We are solely responsible for taxes assessable against Us
          based on Our income, services, property, and employees.
        </div>
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        5. Stoppage of Services
      </span>
      <div className="font-asap">
        <span className="font-dinbold">5.1. </span>In case of Your failure, or
        delay, to pay any fees, We may suspend or cease any or all Services
        being rendered to You, regardless to whether the service is provided by
        one or all Drummond legal entities, affiliates, or Temporary
        Specialists. The Stoppage of Services shall not release You from the
        contractual obligations and responsibilities’ stated herein, nor shall
        invalidate any penalties hereunder.
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        6. Confidentiality
      </span>
      <div>
        <span className="font-dinbold">6.1</span> Drummond acknowledges that, in
        the course of Our engagement with You, Drummond and Temporary
        Specialists may receive or learn certain information and materials
        concerning Your technology, business plan, client list, business
        strategy, services and products, and other information related to Your
        business that are confidential and of substantial value to You (the
        “Confidential Information”). During the term of this Agreement and
        thereafter, Drummond and Temporary Specialists shall hold strictly
        confidential and shall not disclose to others any of the Confidential
        Information learned or received. Drummond and Temporary Specialists
        shall not use any of the Confidential Information other than as part of
        the duties defined under the Scope of Services and for Your sole
        benefit.
      </div>
      <br />
      <div>
        <span className="font-dinbold">6.2</span> The Confidential Information
        shall not include material that Drummond can by reasonable proof (i)
        show that in its disclosed combination(s), is lawfully in the public
        domain through no fault of Drummond; (ii) show that such information is
        contained in a written record in Drummond’s files prior to the date on
        which Drummond received such information from You; (iii) show that
        Drummond had at any time lawfully obtained said information from a third
        party under circumstances permitting its disclosure and use; (iv) show
        that the information is, or has been, disclosed by You to others on an
        unrestricted and non-confidential basis; (v) show the information is
        convenient to substantiate Drummond’s defense in a law suit initiated by
        You, Your agents or representatives in connection with this Agreement;
        or (vi) demonstrate that such information was independently developed by
        Drummond, Temporary Specialists or Drummond’s employees who had
        authorized access to the information disclosed pursuant to this
        Agreement.
      </div>
      <br />
      <div>
        <span className="font-dinbold">6.3</span> Nothing in this Agreement will
        be construed to prevent Drummond from disclosing Confidential
        Information as required by: (i) any law (including, where applicable,
        any order of any governmental, semi-governmental, regulatory,
        administrative, fiscal, judicial or quasi-judicial body, department,
        commission, authority, tribunal, agency or entity); or (ii) by the rules
        or requirements of any relevant stock exchange; provided that Drummond
        promptly notifies You of its obligation to disclose and provides
        reasonable cooperation to You in any efforts to contest or limit the
        scope of such obligations.
      </div>
      <br />
      <div>
        <span className="font-dinbold">6.4</span> Drummond may disclose in its
        marketing material (including its website) and to prospective clients,
        solely for reference purposes, the fact that You have retained Drummond
        or is a client of Drummond, provided, however, that Drummond shall not
        disclose the scope, length and results of the work performed without
        Your prior written consent.
      </div>
      <br />
      <div>
        <span className="font-dinbold">6.5</span> All right, title and interest
        in and to Confidential Information will remain in Your property or of
        Your subsidiaries or affiliates.
      </div>
      <br />
      <div>
        <span className="font-dinbold">6.6</span> Drummond will retain all
        right, title and interest in and to Drummond work product and other
        Drummond intellectual property. You agree that Drummond work product and
        working papers are confidential and intended for the sole use by You.
        Drummond’s work product and working papers shall not be published,
        showed or provided to any other party without Drummond’s prior written
        consent
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        7. Limitation of Liability and Indemnification
      </span>
      <br />
      <div className="font-asap">
        <span class="font-dinbold">7.1. </span>
        Each Party’s liability (whether in contract, tort, negligence, strict
        liability in tort or by statute or otherwise) to the other Party, for
        any and all claims, shall not exceed in the aggregate the total payment
        made under this Agreement in the twelve months preceding the first
        incident out of which the liability arose. In no event shall either
        Party be liable for any special, incidental, indirect, exemplary,
        contingent, punitive or consequential damages arising out of or in
        connection with this Agreement, without regard to whether such party has
        been advised of the possibility of such damages. These limitations are
        independent from all other provisions of this Agreement and shall apply
        notwithstanding the failure of any remedy provided herein.
        Notwithstanding the foregoing, the limitations contained in this Section
        7.1 shall not apply to amounts payable pursuant to the indemnification
        obligations set forth in Section 7.3 below.
      </div>
      <br />
      <div className="font-asap">
        <span class="font-dinbold">7.2. </span>
        Except only in the case of Drummond or the Representatives of Drummond’s
        willful misconduct or gross negligence, neither Drummond nor the
        Representatives of Drummond will incur any liability to You and You
        hereby expressly waive and release Drummond and the Representatives of
        Drummond from any and all claims and causes of action which You may at
        any time have against Drummond arising out of, or in connection with,
        any actions taken by Drummond or the Representatives of Drummond in good
        faith within the scope of this Agreement.
      </div>
      <br />
      <div className="font-asap">
        <span class="font-dinbold">7.3. </span>
        You agree to indemnify, hold harmless and defend Drummond against any
        and all losses, expenses and disbursements including, without
        limitation, the reasonable out-of-pocket costs, fees, expenses and
        disbursements, as and when incurred, of investigating, preparing or
        defending any action, suit, proceeding or investigation, directly or
        indirectly caused by, relating to, based upon, arising out of or in
        connection with the engagement of Drummond by You or any services
        rendered pursuant to Our engagement. These indemnification provisions
        extend to the officers, directors, principals, members, managers,
        Temporary Specialists and employees of Drummond (the “Representatives of
        Drummond”).
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        8. Non-solicitation of Employees and Third-Party Contractors
      </span>
      <br />
      <div className="font-asap">
        <span className="font-dinbold">8.1. </span>
        You agree that without expressed written consent, at all times while You
        are employing Our services and for twelve months after termination of
        this Agreement, You will not, directly or indirectly, whether
        individually or as an officer, director, employee, consultant, partner,
        stockholder, individual proprietor, investor, lender, consultant or any
        other capacity whatsoever, solicit, divert, hire, retain (including as a
        consultant), encourage to leave, or accept the employment of any
        employee or third-party contractor of Drummond, or solicit, divert,
        hire, retain (including as a consultant) or accept any former employee
        of Drummond who has left the employment or
        <br />
        <span className="font-dinbold">8.2. </span>
        You acknowledge that any action in contradiction of the non-solicitation
        provision set forth herein constitutes an action against the good faith
        and fair dealing expected from the Parties and a tortious interference
        with a contract, and shall give rise for a payment in the amount equals
        to 50% of the employee’s former annual salary at Drummond or US$25,000,
        whichever is greater, in the benefit of Drummond.
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        9. Compliance anddue Diligence
      </span>
      <div className="font-asap">
        <span className="font-dinbold">9.1. </span>
        We abide to the highest ethical and legal standards for anti-money
        laundry and terrorism prevention. In that connection, We reserve the
        right to screen, monitor, and request additional information from You as
        We deem appropriate or necessary. Therefore, You understand that You
        must provide reasonably requested information regarding You, other
        parties related to You or to a prospective transaction. You hereby
        represent, warrant and guarantee that any prospective transaction is
        not, and will not be, intended to, directly or indirectly, cause,
        promote or otherwise constitute an illicit act, a money laundry
        mechanism and/or a terrorist activity. You must act in good faith to
        immediately inform Us of each of these representations and warranties.
        <div>
          <br />
          <span className="font-dinbold">9.2. </span>
          You acknowledge that if any legal entity or individual located in a
          country with a favorable tax regime (so called “tax heavens”) is
          anyhow involved in a prospective transaction, Drummond will request to
          have access to documents evidencing the final beneficial ownership of
          such entity and that the transaction has been properly reported to the
          appropriate tax and regulatory authorities.
        </div>
        <br />
        <div>
          <span className="font-dinbold">9.3. </span>
          We reserve the right to terminate Our agreement at any time in case of
          Your failure to observe Sections 9.1 and 9.2 above.
        </div>
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        10. Term of Engagement
      </span>
      <div className="font-asap">
        <span className="font-dinbold">10.1. </span>
        <span>
          This Agreement shall be effective as of the date of the appropriate
          Service Order and shall continue in effect indefinitely or until
          completion of the scope of services. Either You or We may also
          terminate this Agreement at any time upon the giving of at least
          thirty (30) days prior written notice to the other party or
          immediately upon abr/each of this Agreement by the other party.
          Termination shall not affect our right to receive payment for services
          performed and reimbursement for out-of-pocket expenses properly
          incurred (in accordance with the terms of this Agreement) up to the
          date of termination of this Agreement or to the date of termination of
          services rendered during the 30 days of notice.
        </span>
        <br />
        <span className="font-dinbold">10.2. </span>
        <span>
          Late payments, among other factors, shall constitute abr/each of this
          Agreement.
        </span>
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        11. Reimbursement Policy
      </span>
      <div className="font-asap">
        <span className="font-dinbold">11.1. </span> We shall reimburse You with
        90% of the total payment/fee agreed in the applicable Service Order;
        provided, however, that Your reimbursement request shall not exceed 7
        calendar days from the date You sign the corresponding Service Order, or
        the date which we initiate the corresponding Service, whichever occurs
        first.
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        12. No Assignmentor Transfer
      </span>
      <div>
        <span className="font-dinbold">12.1. </span>Except for the engagement of
        Temporary Specialists, neither this Agreement, nor any right, obligation
        or interest herein, may be subcontracted, assigned, delegated or
        transferred by Drummond without Your express written consent. Any
        attempted assignment, delegation, transfer, or subcontract by or to a
        third party in violation hereof shall be null and void. Subject to the
        foregoing, this Agreement shall be binding on the parties and their
        successors and assigns.
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        13. Partial Invalidity
      </span>
      <div>
        <span className="font-dinbold">13.1. </span>If any provision of this
        Agreement is held by a court of competent jurisdiction to be illegal,
        invalid or unenforceable, the other provisions shall remain in full
        force and effect, and the illegal, invalid or unenforceable provision
        shall be deemed replaced by a legal, valid and enforceable provision
        that most nearly reflects the intent of the parties in entering into
        this Agreement.
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        14. No Waiver
      </span>
      <div>
        <span className="font-dinbold">14.1. </span>No failure or delay by the
        parties in exercising any right, power or privilege under this Agreement
        shall constitute a waiver thereof. No waiver of abr/each of any
        provision of this Agreement shall constitute a waiver of any prior,
        concurrent or subsequentbr/each of the same or any other provision
        hereof, and no waiver shall be effective unless granted in writing and
        signed by an authorized representative of the waiving party.
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        15. Governing Law and Jurisdiction
      </span>
      <div>
        <span className="font-dinbold">15.1. </span>This Agreement shall be
        governed by and construed and enforced in accordance with the laws of
        the Commonwealth of Massachusetts. The Courts of the Commonwealth of
        Massachusetts shall have exclusive jurisdiction in relation of any
        claim, dispute or difference concerning this Agreement and any matter
        arising from it. The parties hereto irrevocably waive any right they may
        have to object to any action beingbr/ought in these Courts, to claim
        that the action has beenbr/ought to an inconvenient forum or to claim
        that those Courts do not have jurisdiction.
      </div>
      <br />
      <div>
        <span className="font-dinbold">15.2. </span>In case of a fee dispute
        between You and Drummond Legal Advisors PLLC, and with Drummond Legal
        Advisors PLLC only, You shall have the right to arbitrate such fee
        dispute under Part 137 of Title 22 of the Official Compilation of Codes,
        Rules and Regulations of the State of New York.
      </div>
      <br />
      <span className="font-dinbold text-[18px] text-drummond-secondary-50">
        16. Miscellaneous
      </span>
      <div className="font-asap">
        <span className="font-dinbold">16.1. </span>
        The covenants pertaining to confidentiality shall survive indefinitely,
        or to the full extent permitted by law; provided, however, that the
        parties may specifically agree in writing to release all or part of the
        Confidential Information from the confidentiality restrictions imposed
        by this Agreement. The covenants pertaining to governing law and
        jurisdiction shall survive termination of this Agreement. The covenants
        pertaining to indemnification and limitations on liability shall survive
        termination of this Agreement for a term of ten years.
        <br />
        <span className="font-dinbold">16.2. </span>
        This Agreement constitutes the entire understanding between the parties
        with respect to the subject matter and supersedes all prior written and
        oral proposals, understandings, agreements and/or representations with
        respect to the subject matter hereof, all of which are merged herein.
        Any amendment or modification of this Agreement and of the Service Order
        shall be in writing and executed by each of the parties hereto.
        <br />
        <span className="font-dinbold">16.3. </span>
        This Agreement, any Service Order, and Services provided in connection
        herein shall be deemed to be written, executed and provided within the
        territory of the United States of America.
      </div>
      <br />
      <span className="font-dinbold">
        ********************************************************************************
      </span>
      <br />
      <p className="font-dinbold text-[18px]">Drummond Advisors LLC</p>
      <br />
      <p className="font-dinbold text-[18px]">Drummond Consulting LLC</p>
      <br />
      <p className="font-dinbold text-[18px]">Drummond Consultoria CPA Ltda</p>
      <br />
      <p className="font-dinbold text-[18px]">Drummond CPA LLC</p>
      <br />
      <p className="font-dinbold text-[18px]">Drummond Legal Advisors PLLC</p>
      <br />
      <p className="font-dinbold text-[18px]">Drummond Outsourcing Ltda</p>
      <br />
      <p className="font-dinbold text-[18px]">
        Drummond Sociedade de Advogados
      </p>
      <br />
      <p className="font-dinbold text-[18px]">Drummond Ventures LLC</p>
    </div>
  );
}

export default ToSBoard;
