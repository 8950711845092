function TextareaInputComponent() {
    return (
      <div>
        <textarea
          type="textarea"
          required
          placeholder="Your message here!"
          name="message"
        />
      </div>
    );
  }
  
  export default TextareaInputComponent;
  