/* eslint-disable no-unused-vars */
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import logo from "../../assets/logoColor.png";
import TriageContext from "../../context/TriageContext";
import DesktopLanguageBar from "../atoms/DesktopLanguageBar";
import MobileLanguageBar from "../atoms/MobileLanguageBar";

function NavBar() {
  return (
    <nav className="w-full h-20 flex items-center justify-between md:justify-evenly bg-slate-100">
      {/* <div className="w-1/3 hidden md:block"></div> */}
      <div className="flex justify-center pl-2 md:p-0 w-1/2 md:w-1/3">
        <a
          href="https://drummondadvisors.com"
          className="h-full"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={logo}
            alt="logo"
            target="_blank"
            className="h-full w-44 mt-11"
          />
        </a>
      </div>
      {/* <DesktopLanguageBar />
      <MobileLanguageBar /> */}
    </nav>
  );
}

export default NavBar;
