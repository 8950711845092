import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import drummondLogo from "../assets/logo-drummond.jpg";
import TriageContext from "../context/TriageContext";
import "../GenerateDiagnosticLoading.css";
import { BASE_URL } from '../Axios'

function GenerateDiagnostic() {
  const router = useHistory();
  const triageId = router.location.pathname.split(
    "/generatediagnostic/"
  )[1];

  // console.log(triageId)

  const [pageStatus, setPageStatus] = useState("Checking your information...");
  const {
    setIncomingInfo,
    setProposalId,
    setSignState,
    setUserEmail,
    setProposalGeneratedStatus
  } = useContext(TriageContext);
  const decodeUser = async () => {
    try {
      const token = await getTokenFirebase(triageId);
      const url = `${BASE_URL}/zoho/decodepayload`;
      const result = await axios({
        method: "post",
        baseURL: url,
        data: {
          dataString: token,
        },
      });
      // console.log(result.data);
      return result.data;
    } catch (err) {
      console.error(err);
    }
  };
  const getProposalFirebase = async (payload) => {
    try {
      const url = `${BASE_URL}/firebase/${payload.triageId}`;
      const proposal = await axios({
        method: "get",
        baseURL: url,
      }).then((proposal) => proposal);
      return proposal;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const validateProposal = async (payload) => {
    const result = await getProposalFirebase(payload);
    if (result) {
      setProposalId(result.data.proposal.id);
      setSignState({ ...result.data.proposalSign });
      setPageStatus("Redirecting you to the Diagnostic page...");
      setIncomingInfo(payload);
      setProposalGeneratedStatus("See my proposal");
      setTimeout(() => {
        router.push(`/diagnostics`);
      }, 4000);
    } else {
      generateProposal(payload);
      setIncomingInfo(payload);
      setPageStatus("Redirecting you to the Diagnostic page...");
      setProposalGeneratedStatus("Generate proposal");
      setTimeout(() => {
        router.push(`/diagnostics`);
      }, 4000);
    }
  };

  const saveToFirebase = async (proposalId, accessData) => {
    const proposal = await axios({
      method: "get",
      url: `${BASE_URL}/zoho/proposal/${proposalId}`,
    }).then((proposal) => proposal);

    await axios({
      method: "post",
      baseURL: `${BASE_URL}/firebase`,
      data: {
        id: proposal.Triage_ID,
        signature: {
          name: "",
          time: "",
          signedStatus: false,
        },
        proposal: proposal.data,
        triageAccessData: accessData,
      },
    }).then((response) => response);
  };

  const getTokenFirebase = async (triageId) => {
    const triage = await axios({
      method: "get",
      url: `${BASE_URL}/firebase/triage/${triageId}`,
    }).then((proposal) => proposal);
    setUserEmail(triage.data.Email);
    return triage?.data.token;
  };

  const generateProposal = async (payload) => {
    console.log("Generating diagnostic and Proposal on CRM");
    const response = await axios({
      method: "post",
      baseURL: `${BASE_URL}/zoho/generateproposal`,
      data: {
        email: payload?.data[0].Email,
        servicesID: payload?.servicesID,
        userType: payload?.userType,
        answers: payload?.answers,
        triageId: payload?.triageId,
        accessCode: payload?.accessCode,
      },
    }).then((response) => {
      // console.log(response);
      return response;
    });
    setProposalId(response.data.details.id);
    saveToFirebase(response.data.details.id, payload?.triageAccessData);
    console.log("Proposal Generated, redirecting to your diagnostic...");
    setPageStatus("Redirecting you to the Diagnostic page...");
  };

  useEffect(() => {
    (async () => {
      const decodedResult = await decodeUser();
      console.log(decodedResult);
      validateProposal(decodedResult);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="border-4 border-red-800 h-screen flex flex-col justify-center items-center">
      <img src={drummondLogo} width="200" height="200" alt="logo" />
      <p className="flex text-xl">{pageStatus}</p>
      <div className="flex justify-center h-32 w-32">
        <div className="generateLoading">Loading...</div>
      </div>
    </div>
  );
}

export default GenerateDiagnostic;
