function CheckboxInputComponent(props) {
    const { information } = props;
    return (
      <div>
        {information.map((opt) => (
          <>
            <input id={opt.name.split(' ').join('_')} type="checkbox" />
            <label htmlFor={opt.name.split(' ').join('_')}>{opt.name}</label>
          </>
        ))}
      </div>
    );
  }
  
  export default CheckboxInputComponent;
  