import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TriageContext from "../../context/TriageContext";

export default function StepsBar(props) {
  const STEP = "step step-neutral cursor-pointer";
  const STEP_PRIMARY = "step step-primary cursor-pointer";
  const history = useHistory();
  const { signState, userEmail } = useContext(TriageContext);
  const { id } = useParams();
  const [footerState, setFooterState] = useState({
    welcome: STEP_PRIMARY,
    summary: STEP,
    investment: STEP,
    sign: STEP,
    nextSteps: STEP,
  });
  const [validateSignatures, setValidateSignaures] = useState(
    !history.location.pathname.includes("sign")
  );

  useEffect(() => {
    setSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateNextBtn();
  });

  const setSteps = () => {
    const validate = history.location.pathname || "";
    switch (validate) {
      case `/proposal/${id}/investment`:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          sign: STEP,
          nextSteps: STEP,
        });
        break;
      case "/proposal/sign":
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          nextSteps: STEP,
        });
        break;
      case "/proposal/next-steps":
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          nextSteps: STEP_PRIMARY,
        });
        break;
      case `/proposal/${id}/sign`:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          nextSteps: STEP,
        });
        break;
      case `/proposal/${id}/next-steps`:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP_PRIMARY,
          sign: STEP_PRIMARY,
          nextSteps: STEP_PRIMARY,
        });
        break;
      default:
        setFooterState({
          welcome: STEP_PRIMARY,
          investment: STEP,
          sign: STEP,
          nextSteps: STEP,
        });
    }
  };

  const redirectToPage = (route) => {
    if (route === "checkout") {
      history.push(`/${route}/${id}`);
    } else {
      history.push(`/proposal/${id}/${route}`);
    }
  };

  const changeRoute = (type) => {
    const route = history.location.pathname;
    if (type === "next") {
      switch (route) {
        case `/proposal/${id}/welcome`:
          redirectToPage("investment");
          break;
        case `/proposal/${id}/investment`:
          redirectToPage("sign");
          break;
        case `/proposal/${id}/sign`:
          redirectToPage("next-steps");
          break;
        default:
          break;
      }
    }
    if (type === "back") {
      switch (route) {
        case `/proposal/${id}/investment`:
          redirectToPage("welcome");
          break;
        case `/proposal/${id}/sign`:
          redirectToPage("investment");
          break;
        case `/proposal/${id}/next-steps`:
          redirectToPage("sign");
          break;
        default:
          break;
      }
    }
  };

  const validateNextBtn = async () => {
    if (
      history.location.pathname.includes("welcome") ||
      history.location.pathname.includes("next")
    ) {
      setValidateSignaures(false);
    }
    if (history.location.pathname.includes("sign")) {
      // signState.forEach(i => {
      if (signState.signedStatus) {
        setValidateSignaures(true);
      } else {
        setValidateSignaures(false);
      }
      // })
    }
  };

  const signNextStep = () => {
    if (history.location.pathname.includes("sign")) {
      if (signState.email === userEmail && signState.signedStatus) {
        redirectToPage("next-steps");
      }
    }
  };

  return (
    <div className="pt-2 hidden md:flex w-full">
      <div className="flex justify-center w-full items-baseline">
        {history.location.pathname.includes("welcome") ? (
          <div className="invisible w-[124px] h-[47px] rounded-[4px] duration-100 font-dinbold text-[16px] text-primary border border-primary font-bold bg-white hover:bg-primary hover:text-white active:text-white active:bg-drummond-secondary-400">
            Previous Step
          </div>
        ) : (
          <button
            className="w-[124px] h-[47px] rounded-[4px] duration-100 font-dinbold text-[16px] text-primary border border-primary font-bold bg-white hover:bg-drummond-secondary-300 hover:text-white active:text-white active:bg-drummond-secondary-400"
            onClick={() => changeRoute("back")}
          >
            Previous Step
          </button>
        )}
        <ul className="hidden py-1 sm:grid w-7/12 lg:w-9/12 text-2xl font-dinlight steps steps-horizontal">
          <li
            className={`${footerState.welcome} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="welcome"
            onClick={() => redirectToPage("welcome")}
          >
            Welcome
          </li>
          <li
            className={`${footerState.investment} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="investment"
            onClick={() => redirectToPage("investment")}
          >
            Investment
          </li>
          {/* <li
            className={`${footerState.investment} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="investment"
            onClick={() => redirectToPage("investment")}
          >
            Investment
          </li> */}
          <li
            className={`${footerState.sign} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="sign"
            onClick={() => redirectToPage("sign")}
          >
            Sign
          </li>
          <li
            className={`${footerState.nextSteps} font-dinlight text-drummond-neutral-300 text-[14px]`}
            name="nextSteps"
            onClick={() => signNextStep()}
          >
            Next Steps
          </li>
        </ul>
        {!validateSignatures ||
        history.location.pathname.includes("welcome") ? (
          <div className="invisible w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400">
            Next Step
          </div>
        ) : (
          <button
            className="w-[124px] h-[47px] rounded-[4px] duration-100 text-[16px] font-dinbold text-white bg-primary hover:bg-drummond-secondary-300 active:bg-drummond-secondary-400"
            onClick={() => changeRoute("next")}
          >
            Next Step
          </button>
        )}
      </div>
    </div>
  );
}
